
.label {
  @apply text-sm font-bold;

  &[data-invalid] {
    color: var(--color-danger);

  }

  &.disabled {
    color: var(--color-muted-text);
  }
}
